var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-row bg-gray-100",
    style: _vm.loadingpage ? 'background-color: #fff;' : '',
    attrs: {
      "id": "app"
    }
  }, [_c('Toast'), !_vm.login ? _c('Login') : _vm._e(), _vm.login && _vm.user && _vm.user.status == 'active' && _vm.$route.name != 'createStore' ? _c('side-bar', {
    attrs: {
      "user": _vm.user
    }
  }) : _vm._e(), _vm.login ? _c('main', {
    staticClass: "flex ml-5 flex-col flex-auto w-full min-w-0",
    class: !_vm.ShowSidebar() || _vm.resize() ? 'mll-0' : ''
  }, [_vm.$route.name != 'createStore' ? _c('top-bar', {
    staticClass: "sticky top-0",
    attrs: {
      "user": _vm.user,
      "idWharhouse": _vm.idWharhouse
    }
  }) : _vm._e(), _c('vue-ins-progress-bar'), _c('router-view', {
    attrs: {
      "currentUser": _vm.user,
      "idWharhouse": _vm.idWharhouse
    }
  }), _vm.$route.name != 'createStore' ? _c('div', {
    staticClass: "relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent"
  }, [_c('span', {
    staticClass: "font-medium text-secondary"
  }, [_vm._v("© " + _vm._s(new Date().getFullYear()))])]) : _vm._e()], 1) : _vm._e(), _vm.loadingpage ? _c('div', {
    attrs: {
      "id": "loadingpage"
    }
  }, [_c('div', {
    staticClass: "loader loader-mixed"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:svgjs": "http://svgjs.com/svgjs",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "120",
      "height": "120"
    }
  }, [_c('svg', {
    staticClass: "lds-microsoft",
    staticStyle: {
      "background": "0 0"
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "preserveAspectRatio": "xMidYMid",
      "viewBox": "0 0 100 100",
      "width": "120",
      "height": "120"
    }
  }, [_c('g', {
    staticClass: "color000 svgShape"
  }, [_c('circle', {
    staticClass: "color564ec1 svgShape",
    attrs: {
      "cx": "73.801",
      "cy": "68.263",
      "r": "3",
      "fill": "#4ec16f",
      "transform": "rotate(125.399 50 50)"
    }
  }, [_c('animateTransform', {
    attrs: {
      "attributeName": "transform",
      "begin": "0s",
      "calcMode": "spline",
      "dur": "1.5s",
      "keySplines": "0.5 0 0.5 1",
      "repeatCount": "indefinite",
      "type": "rotate",
      "values": "0 50 50;360 50 50"
    }
  })], 1), _c('circle', {
    staticClass: "color564ec1 svgShape",
    attrs: {
      "cx": "68.263",
      "cy": "73.801",
      "r": "3",
      "fill": "#4ec16f",
      "transform": "rotate(153.871 50 50)"
    }
  }, [_c('animateTransform', {
    attrs: {
      "attributeName": "transform",
      "begin": "-0.062s",
      "calcMode": "spline",
      "dur": "1.5s",
      "keySplines": "0.5 0 0.5 1",
      "repeatCount": "indefinite",
      "type": "rotate",
      "values": "0 50 50;360 50 50"
    }
  })], 1), _c('circle', {
    staticClass: "color564ec1 svgShape",
    attrs: {
      "cx": "61.481",
      "cy": "77.716",
      "r": "3",
      "fill": "#4ec16f",
      "transform": "rotate(182.969 50 50)"
    }
  }, [_c('animateTransform', {
    attrs: {
      "attributeName": "transform",
      "begin": "-0.125s",
      "calcMode": "spline",
      "dur": "1.5s",
      "keySplines": "0.5 0 0.5 1",
      "repeatCount": "indefinite",
      "type": "rotate",
      "values": "0 50 50;360 50 50"
    }
  })], 1), _c('circle', {
    staticClass: "color564ec1 svgShape",
    attrs: {
      "cx": "53.916",
      "cy": "79.743",
      "r": "3",
      "fill": "#4ec16f",
      "transform": "rotate(213.399 50 50)"
    }
  }, [_c('animateTransform', {
    attrs: {
      "attributeName": "transform",
      "begin": "-0.187s",
      "calcMode": "spline",
      "dur": "1.5s",
      "keySplines": "0.5 0 0.5 1",
      "repeatCount": "indefinite",
      "type": "rotate",
      "values": "0 50 50;360 50 50"
    }
  })], 1), _c('circle', {
    staticClass: "color564ec1 svgShape",
    attrs: {
      "cx": "46.084",
      "cy": "79.743",
      "r": "3",
      "fill": "#4ec16f",
      "transform": "rotate(241.838 50 50)"
    }
  }, [_c('animateTransform', {
    attrs: {
      "attributeName": "transform",
      "begin": "-0.25s",
      "calcMode": "spline",
      "dur": "1.5s",
      "keySplines": "0.5 0 0.5 1",
      "repeatCount": "indefinite",
      "type": "rotate",
      "values": "0 50 50;360 50 50"
    }
  })], 1), _c('circle', {
    staticClass: "color564ec1 svgShape",
    attrs: {
      "cx": "38.519",
      "cy": "77.716",
      "r": "3",
      "fill": "#4ec16f",
      "transform": "rotate(267.328 50 50)"
    }
  }, [_c('animateTransform', {
    attrs: {
      "attributeName": "transform",
      "begin": "-0.312s",
      "calcMode": "spline",
      "dur": "1.5s",
      "keySplines": "0.5 0 0.5 1",
      "repeatCount": "indefinite",
      "type": "rotate",
      "values": "0 50 50;360 50 50"
    }
  })], 1), _c('circle', {
    staticClass: "color564ec1 svgShape",
    attrs: {
      "cx": "31.737",
      "cy": "73.801",
      "r": "3",
      "fill": "#4ec16f",
      "transform": "rotate(289.993 50 50)"
    }
  }, [_c('animateTransform', {
    attrs: {
      "attributeName": "transform",
      "begin": "-0.375s",
      "calcMode": "spline",
      "dur": "1.5s",
      "keySplines": "0.5 0 0.5 1",
      "repeatCount": "indefinite",
      "type": "rotate",
      "values": "0 50 50;360 50 50"
    }
  })], 1), _c('circle', {
    staticClass: "color564ec1 svgShape",
    attrs: {
      "cx": "26.199",
      "cy": "68.263",
      "r": "3",
      "fill": "#4ec16f",
      "transform": "rotate(308.811 50 50)"
    }
  }, [_c('animateTransform', {
    attrs: {
      "attributeName": "transform",
      "begin": "-0.437s",
      "calcMode": "spline",
      "dur": "1.5s",
      "keySplines": "0.5 0 0.5 1",
      "repeatCount": "indefinite",
      "type": "rotate",
      "values": "0 50 50;360 50 50"
    }
  })], 1), _c('animateTransform', {
    attrs: {
      "attributeName": "transform",
      "calcMode": "spline",
      "dur": "1.5s",
      "keySplines": "0.5 0 0.5 1",
      "repeatCount": "indefinite",
      "type": "rotate",
      "values": "0 50 50;0 50 50"
    }
  })], 1)])])]), _c('div', {
    staticClass: "loading-info"
  }, [_vm._v("Loading DATA ...")])]) : _vm._e(), _c('vue-modal-2', {
    attrs: {
      "name": "RefreshPage",
      "headerOptions": {
        title: "Nouvelle mise \xE0 jour"
      },
      "noFooter": true,
      "modalSize": "md"
    }
  }, [_c('div', {
    staticClass: "bg-white rounded-lg"
  }, [_c('div', {
    staticClass: "rounded-lg"
  }, [_c('p', {
    staticClass: "p-4 pt-0 font-bold"
  }, [_c('svg', {
    staticClass: "fill-current mr-1 inline-block",
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "m11.998 2.001c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-4.496 6.028-.002-.825c0-.414-.336-.75-.75-.75s-.75.336-.75.75v3.048c0 .414.336.75.75.75h3.022c.414 0 .75-.336.75-.75s-.336-.756-.75-.756h-1.512c.808-1.205 2.182-1.998 3.74-1.998 2.483 0 4.5 2.016 4.5 4.5 0 2.483-2.017 4.5-4.5 4.5-1.956 0-3.623-1.251-4.242-2.997-.106-.299-.389-.499-.707-.499-.518 0-.88.513-.707 1.001.825 2.327 3.048 3.995 5.656 3.995 3.312 0 6-2.689 6-6 0-3.312-2.688-6-6-6-1.79 0-3.399.786-4.498 2.031z",
      "fill-rule": "nonzero"
    }
  })]), _vm._v(" Une nouvelle mise à jour améliorée de l'application est maintenant disponible, actualiser la page SVP. ")])]), _c('div', {
    staticClass: "p-4 w-full text-right"
  }, [_c('button', {
    staticClass: "w-1/2 px-4 py-3 text-center text-blue-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative",
    on: {
      "click": function click($event) {
        return _vm.RefreshPage();
      }
    }
  }, [_c('i', {
    staticClass: "material-icons text-white mr-1",
    staticStyle: {
      "vertical-align": "middle"
    }
  }, [_vm._v("refresh")]), _vm._v(" Actualiser maintenant ")])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
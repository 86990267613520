import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  /*********************************************************************/
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/createstore',
    name: 'createStore',
    component: () => import('../views/storeino_store.vue'),
    props: true,
  },
  {
    path: '/profil',
    name: 'Home',
    component: () => import('../views/profil.vue'),
  },
   {
    path: '/shippings/verify/',
    name: 'verifyShipments',
    component: () => import('../views/VerifyShipments.vue'),
  },

  /*********************************************************************/
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/users/index.vue'),
    props: true,
  },
  {
    path: '/users/edit/:id',
    name: 'updateUser',
    component: () => import('../views/users/form.vue'),
    props: true,
  },
  {
    path: '/users/new',
    name: 'newUser',
    component: () => import('../views/users/form.vue'),
    props: true,
  },
  {
    path: '/users/history',
    name: 'historyUser',
    component: () => import('../views/users/history.vue'),
    props: true,
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('../views/roles/index.vue'),
    props: true,
  },
  {
    path: '/roles/new',
    name: 'newRole',
    component: () => import('../views/roles/form.vue'),
    props: true,
  },
  {
    path: '/roles/edit/:id',
    name: 'updateRole',
    component: () => import('../views/roles/form.vue'),
    props: true,
  },

  {
    path: '/teams',
    name: 'teams',
    component: () => import('../views/teams/index.vue'),
    props: true,
  },
  {
    path: '/teams/new',
    name: 'newTeam',
    component: () => import('../views/teams/form.vue'),
    props: true,
  },
  {
    path: '/teams/edit/:id',
    name: 'updateTeam',
    component: () => import('../views/teams/form.vue'),
    props: true,
  },

  /***********************************************************************/
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/products/index.vue'),
    props: true,
  },
  {
    path: '/products/:id/analytics',
    name: 'product-analytics',
    component: () => import('../views/products/ProductAnalytics.vue'),
  },
  {
    path: '/products/v2',
    name: 'product-list',
    component: () => import('../views/products/Products.vue'),
  },
  {
    path: '/products/new',
    name: 'newProduct',
    component: () => import('../views/products/form.vue'),
    props: true,
  },
  {
    path: '/products/edit/:id',
    name: 'updateProduct',
    component: () => import('../views/products/form.vue'),
  },
  {
    path: '/products/detail/:id',
    name: 'viewProduct',
    component: () => import('../views/products/view.vue'),
  },
  /***********************************************************************/
  {
    path: '/warehouses',
    name: 'warehouses',
    component: () => import('../views/warehouses/index.vue'),
    props: true,
  },
  {
    path: '/warehouses/new',
    name: 'newWarehouse',
    component: () => import('../views/warehouses/form.vue'),
    props: true,
  },
  {
    path: '/warehouses/edit/:id',
    name: 'updateWarehouse',
    component: () => import('../views/warehouses/form.vue'),
  },
  /***********************************************************************/
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/categories/index.vue'),
    props: true,
  },
  {
    path: '/categories/new',
    name: 'newCategory',
    component: () => import('../views/categories/form.vue'),
    props: true,
  },
  {
    path: '/categories/edit/:id',
    name: 'updateCategory',
    component: () => import('../views/categories/form.vue'),
  },
  {
    path: '/categories/detail/:id',
    name: 'viewCategory',
    component: () => import('../views/categories/view.vue'),
  },
  /***********************************************************************/

  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/orders/index.vue'),
    props: true,
  },
  {
    path: '/orders/new',
    name: 'newOrder',
    component: () => import('../views/orders/form.vue'),
    props: true,
  },
  {
    path: '/orders/edit/:id',
    name: 'updateOrder',
    component: () => import('../views/orders/form.vue'),
  },
  {
    path: '/orders/detail/:id',
    name: 'viewOrder',
    component: () => import('../views/orders/view.vue'),
  },
  {
    path: '/orders/import',
    name: 'importOrders',
    component: () => import('../views/orders/importOrders.vue'),
  },
  {
    path: '/orders/api',
    name: 'apiCreateOrders',
    component: () => import('../views/orders/api.vue'),
  },

  {
    path: '/orders/confirmation',
    name: 'confirmation',
    component: () => import('../views/callcenter/confirmation.vue'),
    props: true,
  },
  /***********************************************************************/
  {
    path: '/expeditions',
    name: 'expeditions',
    component: () => import('../views/expeditions/index.vue'),
    props: true,
  },
  {
    path: '/expeditions/new',
    name: 'newExpedition',
    component: () => import('../views/expeditions/form.vue'),
    props: true,
  },
  {
    path: '/expeditions/edit/:id',
    name: 'updateExpedition',
    component: () => import('../views/expeditions/form.vue'),
  },
  {
    path: '/expeditions/detail/:id',
    name: 'viewExpedition',
    component: () => import('../views/expeditions/view.vue'),
  },
  /********************************************************************** */
  {
    path: '/status',
    name: 'status',
    component: () => import('../views/status/index.vue'),
    props: true,
  },
  {
    path: '/status/new',
    name: 'newStatus',
    component: () => import('../views/status/form.vue'),
    props: true,
  },
  {
    path: '/status/edit/:id',
    name: 'updateStatus',
    component: () => import('../views/status/form.vue'),
  },
  {
    path: '/status/detail/:id',
    name: 'viewStatus',
    component: () => import('../views/status/view.vue'),
  },
  /********************************************************************* */
  {
    path: '#',
    name: 'Shipping order',
    component: () => import('../views/zones/index.vue'),
    props: false,
  },
  {
    path: '/zones',
    name: 'zones',
    component: () => import('../views/zones/index.vue'),
    props: true,
  },
  {
    path: '/zones/new',
    name: 'newZones',
    component: () => import('../views/zones/form.vue'),
    props: true,
  },
  {
    path: '/zones/edit/:id',
    name: 'updateZones',
    component: () => import('../views/zones/form.vue'),
  },
  /********************************************************************* */
  {
    path: '/shippings',
    name: 'shippings',
    component: () => import('../views/shippings/index.vue'),
    props: true,
  },
  {
    path: '/shippings/edit/:id',
    name: 'updateShippings',
    component: () => import('../views/shippings/form.vue'),
  },
  {
    path: '/shippings/view/:id',
    name: 'viewOrder',
    component: () => import('../views/shippings/view.vue'),
  },
  /***********************************************************************/
  {
    path: '/subscribers',
    name: 'subscribers',
    component: () => import('../views/subscribers/index.vue'),
    props: true,
  },
  {
    path: '/subscribers/new',
    name: 'newSubscribers',
    component: () => import('../views/subscribers/form.vue'),
    props: true,
  },
  {
    path: '/subscribers/edit/:id',
    name: 'updateSubscribers',
    component: () => import('../views/subscribers/form.vue'),
  },
  {
    path: '/subscribers/detail/:id',
    name: 'viewSubscribers',
    component: () => import('../views/subscribers/view.vue'),
  },
  {
    path: '/callcenter',
    name: 'callcenter',
    component: () => import('../views/callcenter/analytics.vue'),
    props: true,
  },

  {
    path: '/callcenter/analytics/view/:id',
    name: 'viewanalytics',
    component: () => import('../views/callcenter/viewGraph.vue'),
  },
  {
    path: '/callcenter/analytics/viewStatus/:id',
    name: 'viewStatus',
    component: () => import('../views/callcenter/viewStatus.vue'),
  },

  /********************************************************************* */
  {
    path: '#',
    name: 'Payments',
    component: () => import('../views/payments/index.vue'),
    props: false,
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('../views/payments/index.vue'),
    props: true,
  },
  {
    path: '/expenses',
    name: 'expenses',
    component: () => import('../views/Expenses.vue'),
    props: true,
  },
  {
    path: '/spends',
    name: 'spends',
    component: () => import('../views/SpendsView.vue'),
    props: true,
  },
  {
    path: '/payments/new',
    name: 'newPayments',
    component: () => import('../views/payments/form.vue'),
    props: true,
  },
  {
    path: '/payments/detail/:id',
    name: 'viewpayment',
    component: () => import('../views/payments/view.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/settings/form.vue'),
    props: true,
  },
  {
    path: '/settings/features',
    name: 'features',
    component: () => import('../views/settings/FeaturesView.vue'),
    props: true,
  },

  {path: '*', redirect: '/'},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  //const { log } = to.meta;

  next()
})

export default router

const arrayTrans = ["global"];
let trans = {};
let data = require(`./data/global.json`);
trans = { ...data, ...trans };
// for (const key in arrayTrans) {
//     let data  = require(`./data/global.json`);
//     if (arrayTrans[key] == 'global') {
//         trans = { ...data, ...trans };
//     }else {
//         for (const key1 in data) {
//             trans[arrayTrans[key] + '_' + key1] = data[key1];
//         }
//     }
// }
//
export default trans;

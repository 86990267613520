import "@/assets/css/main.css";
import "@/assets/css/tailwind.css";
import "@/assets/style.css";
import "material-design-icons/iconfont/material-icons.css";
import "primeicons/primeicons.css";
import "vue-datetime/dist/vue-datetime.css";
import "vue-select/dist/vue-select.css";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
//theme
import "primevue/resources/themes/lara-light-teal/theme.css";

//core
import "primevue/resources/primevue.min.css";

import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

import Chakra, { CReset, CThemeProvider } from "@chakra-ui/vue";
import PrimeVue from "primevue/config";

import Vue from "vue";
import vSelect from "vue-select";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import countries from "@/assets/data/countries.json";
import Progress from "easy-circular-progress";
import moment from "moment";
import momentTimezone from "moment-timezone";
import VueInsProgressBar from "vue-ins-progress-bar";
import colors from "./plugins/colors";
import functions from "./plugins/functions";
import jwtService from "./plugins/jwt.service";
import server from "./plugins/server";

import VueSimpleAlert from "vue-simple-alert";
import components from "./components";

import Swal from 'sweetalert2';





import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

import HighchartsVue from "highcharts-vue";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/fr";

Vue.use(HighchartsVue, { tagName: "highcharts" });

Vue.component("apexchart", VueApexCharts);
Vue.component("date-picker", DatePicker);

// i18n
import i18n from "./i18n/i18n";

//loiding plugin
import Modal from "@burhanahmeed/vue-modal-2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.component("loading", Loading);
Vue.component("Progress", Progress);
Vue.use(Modal);
Vue.use(VueSimpleAlert);

const options = {
  position: "fixed",
  show: true,
  height: "8px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
};
Vue.use(Chakra);
Vue.use(PrimeVue);
Vue.use(ToastService);
Vue.use(ConfirmationService);
Vue.use(VueInsProgressBar, options);
//Vue.use(Swal);

import { Datetime } from "vue-datetime";
Vue.component("datetime-v2", Datetime);

import VueBarcode from "@chenfengyuan/vue-barcode";
Vue.component("vue-barcode", VueBarcode);

components.forEach((c) => {
  Vue.component(c.name, c.component);
});
Vue.component("v-select", vSelect);

Vue.prototype.$f = functions;
Vue.prototype.$server = server;
Vue.prototype.$colors = colors;
Vue.prototype.$jwtService = jwtService;
Vue.prototype.$me = server.me("users");
Vue.prototype.$moment = moment;
Vue.prototype.$countries = countries;
Vue.prototype.$momentTimezone = momentTimezone;
Vue.prototype.$swal = Swal;


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(CThemeProvider, [h(CReset), h(App)]),
}).$mount("#app");

import axios from "../../common/axios";

const state = () => ({
  sellers: [],
  defaultSeller: null,
  warehouses: [],
  defaultWarehouse: null,
});

const getters = {};

const actions = {
  async getAllSellers({ commit }, payload) {
    await axios
      .get(`/users/search?type=Seller&countries=${payload.country}`)
      .then(({ data }) => {
        commit("setSellers", data.content.results);
      });
  },
  async geAllWarehouses({ commit }, payload) {
    await axios.get(`/warehouses/search?limit=1000`).then(({ data }) => {
      commit("setWarehouses", data.content.results);
    });
  },
  defineDefaultSeller({ commit }, payload) {
    if (payload) {
      commit("setDefaultSeller", payload);
    } else {
      const defaultSeller = localStorage.getItem("default-seller");
      if (defaultSeller) {
        commit("setDefaultSeller", defaultSeller);
      }
    }
  },
  defineDefaultWarehouse({ commit }, payload) {
    if (payload) {
      commit("setDefaultWarehouse", payload);
    } else {
      const defaultWarehouse = localStorage.getItem("default-warehouse");
      if (defaultWarehouse) {
        commit("setDefaultWarehouse", defaultWarehouse);
      }
    }
  },
};

const mutations = {
  setSellers(state, sellers) {
    state.sellers = sellers;
  },
  setDefaultSeller(state, sellerId) {
    localStorage.setItem("default-seller", sellerId);
    state.defaultSeller = sellerId;
  },
  setDefaultWarehouse(state, warehouseId) {
    localStorage.setItem("default-warehouse", warehouseId);
    state.defaultWarehouse = warehouseId;
  },
  setWarehouses(state, warehouses) {
    state.warehouses = warehouses;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import server from "../../plugins/server";
import $f from "../../plugins/functions";
import countries from "../../assets/data/countries.json";
export default {
  namespaced: true,
  state() {
    return {
      warhouseSelected: localStorage.getItem("countryW") || "CI",
      changeW: false,
      user: {},
      warhouses: [],
      options: countries,
      warehouses: [],
      warehousesUser: [],
      warehousesSeller: [],
    };
  },

  mutations: {
    changeWrahouse: function (state, payload) {
      state.warhouseSelected = payload.warhouse;
      state.changeW = true;
    },
    getWarehouses: function (state, payload) {
      state.user = payload.user;
    },
  },
  actions: {
    changeWrahouse(context, payload) {
      context.commit("changeWrahouse", payload);
    },
    async getwharhouseId({ getters }, payload) {
      const resW = await server.get("warehouses", { country: payload.country });
      if (resW.content) return resW.content;
    },
    async getCurrency({ getters, state }, payload) {
      if (state.warehouses && state.warhouseSelected) {
        const dataW = state.warehouses.find(
          (o) => o.code === state.warhouseSelected
        );
        if (dataW) return dataW.currency;
      }
      // const resW = await server.get("warehouses", { country: payload.country});
      // if(resW.content) return  resW.content.currency;
    },
    async getwharhouseSeller() {
      const resW = await server.get("products", { allWarhouseSeller: true });
      return resW.content;
    },
    async searchUser({ commit }, payload) {
      if (payload.type != "zone") {
        const filter = {
          type: payload.type[0].toUpperCase() + payload.type.slice(1),
          limit: 10,
          countries: payload.warhouse,
          name: payload.search,
        };

        const res = await server.find("users", filter);

        if (res.content) return res.content.results;
        else return [];
      } else {
        const filter = {
          limit: 10,
          country: payload.warhouse,
          name: payload.search,
        };

        const res = await server.find("zones", filter);

        if (res.content.results) return res.content.results;
        else return [];
      }
    },
    async searchProducts({ commit }, payload) {
      const filter = {
        limit: 10,
        seller: payload.seller,
        "details.warehouse": payload.warhouse,
        name: payload.search,
      };

      const res = await server.find("products", filter);

      if (res.content && res.content.results) return res.content.results;
      else return [];
    },
    async getWarehouses({ commit, state }, payload) {
      commit("getWarehouses", payload);
      const filter = { limit: 1000 };
      state.warehouses = [];
      state.warehousesUser = [];

      //  const resUser = await server.me("users");
      if (state.user) {
        if (state.user.countries) {
          state.warehousesUser = state.user.countries;
          // if(state.user.type=="Seller"){
          //     const resWExpe = await server.get("expeditions", { allWarhouseSeller: true});
          //     if(resWExpe.content.length>0){
          //         for(let i in resWExpe.content){
          //             if(state.warehousesUser.indexOf(resWExpe.content[i]) === -1) state.warehousesUser.push(resWExpe.content[i])
          //         }
          //     }
          // }
          for (let i in state.warehousesUser) {
            if (!state.warehousesUser[i].name && !state.warehousesUser[i].code)
              state.warehousesUser[i] = {
                name: $f.getCountryNameByCode(
                  state.options,
                  state.warehousesUser[i]
                ),
                code: state.warehousesUser[i],
                currency: $f.getCurrencyByCode(
                  state.options,
                  state.warehousesUser[i]
                ),
              };
          }
        }
      }

      if (state.warehousesUser.length > 0) {
        state.warehouses = state.warehousesUser;
      } else {
        const res = await server.search("warehouses", filter);
        if (res.content.results) {
          let data = res.content.results;
          for (let i in data) {
            state.warehouses.push({
              name: $f.getCountryNameByCode(state.options, data[i].country),
              code: data[i].country,
              currency: $f.getCurrencyByCode(state.options, data[i].country),
            });
          }
        } else state.warehouses = [];
      }
      return state.warehouses;
    },
  },
  getters: {
    warhouseSelected: (state) => async (data) => {
      if (!state.changeW) {
        //const resW = await server.get("products", { allWarhouseSeller: true});
        // const resUser = await server.me("users");
        //check if user has countries warhouse
        if (
          data.user &&
          data.user.countries &&
          data.user.countries.length > 0
        ) {
          // if(localStorage.getItem('countryW')){
          //     if(resUser.content.countries.indexOf(localStorage.getItem('countryW'))===-1){
          //         //return resUser.content.countries[0];
          //     }
          // }

          if (data.user.countries.length == 1) {
            state.warhouseSelected =
              data.user.countries[0].code || data.user.countries[0];
            localStorage.setItem("countryW", state.warhouseSelected);
          }
          return (
            localStorage.getItem("countryW") ||
            data.user.countries[0].code ||
            data.user.countries[0]
          );
        }
      }

      return state.warhouseSelected;
    },
    // async warehouses(state){
    //
    //     const filter = {limit:1000};
    //     state.warehousesUser=[];
    //     state.warehouses=[];
    //     const resUser = await server.me("users");
    //     if (resUser.content){
    //         if(resUser.content.countries) {
    //             state.warehousesUser = resUser.content.countries;
    //             if(resUser.content.type=="Seller"){
    //                 const resWExpe = await server.get("expeditions", { allWarhouseSeller: true});
    //                 if(resWExpe.content.length>0){
    //                     for(let i in resWExpe.content){
    //                         if(state.warehousesUser.indexOf(resWExpe.content[i]) === -1) state.warehousesUser.push(resWExpe.content[i])
    //                     }
    //                 }
    //             }
    //             for(let i in state.warehousesUser){
    //                 state.warehousesUser[i]={name: $f.getCountryNameByCode(state.options,state.warehousesUser[i]),code:state.warehousesUser[i]}
    //             }

    //         }
    //     }

    //     if(state.warehousesUser.length>0){
    //         state.warehouses= state.warehousesUser;
    //     }else{
    //       const res = await server.search("warehouses",filter);
    //       if (res.content.results) {
    //           let data = res.content.results;
    //           for(let i in data){
    //             state.warehouses.push({name:$f.getCountryNameByCode(state.options,data[i].country),code:data[i].country})
    //           }
    //       } else state.warehouses = [];
    //   }
    //   return  state.warehouses;
    // },
    warehouses: (state) => async (data) => {
      const filter = { limit: 1000 };
      state.warehousesUser = [];
      state.warehouses = [];
      // const resUser = await server.me("users");
      if (data.user) {
        if (data.user.countries) {
          state.warehousesUser = data.user.countries;
          // if(resUser.content.type=="Seller"){
          //     const resWExpe = await server.get("expeditions", { allWarhouseSeller: true});
          //     if(resWExpe.content.length>0){
          //         for(let i in resWExpe.content){
          //             if(state.warehousesUser.indexOf(resWExpe.content[i]) === -1) state.warehousesUser.push(resWExpe.content[i])
          //         }
          //     }
          // }

          for (let i in state.warehousesUser) {
            if (!state.warehousesUser[i].name && !state.warehousesUser[i].code)
              state.warehousesUser[i] = {
                name: $f.getCountryNameByCode(
                  state.options,
                  state.warehousesUser[i]
                ),
                code: state.warehousesUser[i],
                currency: $f.getCurrencyByCode(
                  state.options,
                  state.warehousesUser[i]
                ),
              };
          }
        }
      }

      if (state.warehousesUser.length > 0) {
        state.warehouses = state.warehousesUser;
      } else {
        const res = await server.search("warehouses", filter);
        if (res.content.results) {
          let data = res.content.results;
          for (let i in data) {
            state.warehouses.push({
              name: $f.getCountryNameByCode(state.options, data[i].country),
              code: data[i].country,
              currency: $f.getCurrencyByCode(state.options, data[i].country),
            });
          }
        } else state.warehouses = [];
      }

      return state.warehouses;
    },
  },
};

<template>
  <div
    id="app"
    class="flex flex-row bg-gray-100"
    :style="loadingpage ? 'background-color: #fff;' : ''"
  >
    <Toast />

    <Login v-if="!login"> </Login>

    <side-bar
      v-if="
        login && user && user.status == 'active' && $route.name != 'createStore'
      "
      :user="user"
    ></side-bar>
    <main
      v-if="login"
      :class="!ShowSidebar() || resize() ? 'mll-0' : ''"
      class="flex ml-5 flex-col flex-auto w-full min-w-0"
    >
      <top-bar
        v-if="$route.name != 'createStore'"
        :user="user"
        :idWharhouse="idWharhouse"
        class="sticky top-0"
      ></top-bar>
      <vue-ins-progress-bar></vue-ins-progress-bar>
      <router-view :currentUser="user" :idWharhouse="idWharhouse" />
      <div
        v-if="$route.name != 'createStore'"
        class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent"
      >
        <span class="font-medium text-secondary"
          >© {{ new Date().getFullYear() }}</span
        >
      </div>
    </main>

    <div v-if="loadingpage" id="loadingpage">
      <div class="loader loader-mixed">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:svgjs="http://svgjs.com/svgjs"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="120"
          height="120"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="lds-microsoft"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 100 100"
            style="background: 0 0"
            width="120"
            height="120"
          >
            <g class="color000 svgShape">
              <circle
                cx="73.801"
                cy="68.263"
                r="3"
                fill="#4ec16f"
                class="color564ec1 svgShape"
                transform="rotate(125.399 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  begin="0s"
                  calcMode="spline"
                  dur="1.5s"
                  keySplines="0.5 0 0.5 1"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 50 50;360 50 50"
                />
              </circle>
              <circle
                cx="68.263"
                cy="73.801"
                r="3"
                fill="#4ec16f"
                class="color564ec1 svgShape"
                transform="rotate(153.871 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  begin="-0.062s"
                  calcMode="spline"
                  dur="1.5s"
                  keySplines="0.5 0 0.5 1"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 50 50;360 50 50"
                />
              </circle>
              <circle
                cx="61.481"
                cy="77.716"
                r="3"
                fill="#4ec16f"
                class="color564ec1 svgShape"
                transform="rotate(182.969 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  begin="-0.125s"
                  calcMode="spline"
                  dur="1.5s"
                  keySplines="0.5 0 0.5 1"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 50 50;360 50 50"
                />
              </circle>
              <circle
                cx="53.916"
                cy="79.743"
                r="3"
                fill="#4ec16f"
                class="color564ec1 svgShape"
                transform="rotate(213.399 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  begin="-0.187s"
                  calcMode="spline"
                  dur="1.5s"
                  keySplines="0.5 0 0.5 1"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 50 50;360 50 50"
                />
              </circle>
              <circle
                cx="46.084"
                cy="79.743"
                r="3"
                fill="#4ec16f"
                class="color564ec1 svgShape"
                transform="rotate(241.838 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  begin="-0.25s"
                  calcMode="spline"
                  dur="1.5s"
                  keySplines="0.5 0 0.5 1"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 50 50;360 50 50"
                />
              </circle>
              <circle
                cx="38.519"
                cy="77.716"
                r="3"
                fill="#4ec16f"
                class="color564ec1 svgShape"
                transform="rotate(267.328 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  begin="-0.312s"
                  calcMode="spline"
                  dur="1.5s"
                  keySplines="0.5 0 0.5 1"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 50 50;360 50 50"
                />
              </circle>
              <circle
                cx="31.737"
                cy="73.801"
                r="3"
                fill="#4ec16f"
                class="color564ec1 svgShape"
                transform="rotate(289.993 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  begin="-0.375s"
                  calcMode="spline"
                  dur="1.5s"
                  keySplines="0.5 0 0.5 1"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 50 50;360 50 50"
                />
              </circle>
              <circle
                cx="26.199"
                cy="68.263"
                r="3"
                fill="#4ec16f"
                class="color564ec1 svgShape"
                transform="rotate(308.811 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  begin="-0.437s"
                  calcMode="spline"
                  dur="1.5s"
                  keySplines="0.5 0 0.5 1"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 50 50;360 50 50"
                />
              </circle>
              <animateTransform
                attributeName="transform"
                calcMode="spline"
                dur="1.5s"
                keySplines="0.5 0 0.5 1"
                repeatCount="indefinite"
                type="rotate"
                values="0 50 50;0 50 50"
              />
            </g>
          </svg>
        </svg>
      </div>
      <div class="loading-info">Loading DATA ...</div>
    </div>
    <vue-modal-2
      name="RefreshPage"
      :headerOptions="{ title: `Nouvelle mise à jour` }"
      :noFooter="true"
      modalSize="md"
    >
      <div class="bg-white rounded-lg">
        <div class="rounded-lg">
          <p class="p-4 pt-0 font-bold">
            <svg
              class="fill-current mr-1 inline-block"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m11.998 2.001c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-4.496 6.028-.002-.825c0-.414-.336-.75-.75-.75s-.75.336-.75.75v3.048c0 .414.336.75.75.75h3.022c.414 0 .75-.336.75-.75s-.336-.756-.75-.756h-1.512c.808-1.205 2.182-1.998 3.74-1.998 2.483 0 4.5 2.016 4.5 4.5 0 2.483-2.017 4.5-4.5 4.5-1.956 0-3.623-1.251-4.242-2.997-.106-.299-.389-.499-.707-.499-.518 0-.88.513-.707 1.001.825 2.327 3.048 3.995 5.656 3.995 3.312 0 6-2.689 6-6 0-3.312-2.688-6-6-6-1.79 0-3.399.786-4.498 2.031z"
                fill-rule="nonzero"
              />
            </svg>
            Une nouvelle mise à jour améliorée de l'application est maintenant
            disponible, actualiser la page SVP.
          </p>
        </div>
        <div class="p-4 w-full text-right">
          <button
            @click="RefreshPage()"
            class="w-1/2 px-4 py-3 text-center text-blue-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative"
          >
            <i
              style="vertical-align: middle"
              class="material-icons text-white mr-1"
              >refresh</i
            >
            Actualiser maintenant
          </button>
        </div>
      </div>
    </vue-modal-2>
  </div>
</template>
<script>
//import button from './components/~/button.vue';
import Toast from "primevue/toast";
export default {
  components: { Toast },
  data() {
    return {
      login: false,
      loading: false,
      loadingpage: false,
      fullPage: false,
      user: {},
      typeUser: "",
      idWharhouse: null,
    };
  },
  created() {
    if (document.readyState == "loading") {
      this.loadingpage = true;
    }
    let vm = this;
    document.addEventListener("readystatechange", function (ev) {
      if (document.readyState == "complete") {
        setTimeout(function () {
          vm.loadingpage = false;
        }, 800);
      }
    });

    this.$store.dispatch("features/getAllFeatures");
    this.$store.dispatch("app/geAllWarehouses");
    this.$store.dispatch("app/getAllSellers", {
      country: this.$store.state.wharhouse.warhouseSelected,
    });
    this.$store.dispatch("app/defineDefaultSeller");
    this.$store.dispatch("app/defineDefaultWarehouse");
  },

  watch: {
    "$store.state.loading": async function (oldVal, newVal) {
      this.loadingpage = oldVal;
    },
    "$store.state.update": async function (oldVal, newVal) {
      if (oldVal) this.openClosePopup("open");
      //this.loadingpage=oldVal
    },
    "$store.state.wharhouse.warhouseSelected": {
      async handler(newValue, oldValue) {
        await this.getwharhouseId(newValue.code || newValue);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getAccess();
    //await this.getwharhouseId(this.$store.state.wharhouse.warhouseSelected);
    document.onreadystatechange = (event) => {
      if (document.readyState == "complete") {
        this.loadingpage = false;
      }
    };
  },
  methods: {
    async getwharhouseId(country) {
      const resW = await this.$server.get("warehouses", { country: country });
      if (resW && resW.content) this.idWharhouse = resW.content;
    },
    RefreshPage() {
      if (this.user && this.user._id) {
        localStorage.setItem("pr-version", this.$store.state.pr_version);
        window.location.reload();
      }
    },
    openClosePopup(type) {
      if (type == "open") this.$vm2.open("RefreshPage");
      else this.$vm2.close("RefreshPage");
    },
    ShowSidebar() {
      return this.$store.getters.ShowSidebar;
    },
    resize() {
      return this.$store.getters.resize;
    },
    async getAccess() {
      this.loading = true;
      const tokenExist = this.$jwtService.verifyToken();
      if (tokenExist) {
        const token = this.$jwtService.getToken();
        const dataUser = await this.$me;
        if (dataUser && dataUser.status && dataUser.status == 200) {
          this.typeUser = dataUser.content.type;
          this.user = dataUser.content;
          const resW = await this.$server.get("warehouses", {
            country: this.$store.state.wharhouse.warhouseSelected,
          });
          if (resW && resW.content) this.idWharhouse = resW.content;
          this.login = true;
        }
      }
      this.loading = false;
    },
  },
};
</script>
<style>
#vm2_box_ModalChangeStatusByDauchette .vm2__close,
#vm2_box_RefreshPage .vm2__close,
#vm2_box_ModalPay .vm2__close {
  display: none;
}

.mx-datepicker {
  width: 100%;
}

.sucess {
  color: #1b9c0d;
}

.input-style {
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #9493a9 !important;
  background-color: #f4f3f9;
  background-clip: padding-box;
  border: 1px solid #e1e0ea;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 3px;
}

.bg-dauch {
  border: 2px solid #dcdcde;
  border-radius: 0.25rem;
  background-color: #fff;
}

.w-full-imp {
  width: 100% !important;
}

.upload-dropzone-border {
  border: 0;
  background-image: url("data:image/svg+xml, %3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4px' ry='4px' stroke='%2389888D' stroke-width='2' stroke-dasharray='6%2c4' stroke-dashoffset='0' stroke-linecap='butt' /%3e %3c/svg%3e");
  border-radius: 4px;
}

#loadingpage {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.97;
  background-color: #fff;
  z-index: 10000;
}

#loadingpage .loader {
  position: absolute;
  z-index: 30;
  left: 50%;
  width: 120px;
  margin-left: -65px;
  margin-bottom: 30px;
}

#loadingpage .loader .spinner-ball-outer,
.ecwid-loadingIndicator .loader .spinner-ball-outer {
  width: 88px;
  height: 88px;
  background: transparent
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='88' viewBox='0 0 88 88'%3E%3Cpath fill='none' stroke='%23275ce0' stroke-linecap='round' stroke-width='3' d='M64.8 79.5C58.4 83.3 51 85.5 43 85.5 27 85.5 13 76.6 5.8 63.5M64.4 6.2C77 13.6 85.5 27.3 85.5 43 85.5 50.7 83.4 58 79.8 64.2M.5 43C.5 19.5 19.5.5 43 .5' transform='translate(1 1)'/%3E%3C/svg%3E")
    center center no-repeat;
  background-size: contain;
  animation: spinright 0.8s infinite linear;
}

#loadingpage .loader .spinner-ball-inner,
.ecwid-loadingIndicator .loader .spinner-ball-inner {
  width: 40px;
  height: 40px;
  margin: -64px auto 64px;
  background: transparent
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath fill='%23275ce0' d='M20 39.5C9.2 39.5.5 30.8.5 20S9.2.5 20 .5 39.5 9.2 39.5 20c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5c0-9.1-7.4-16.5-16.5-16.5S3.5 10.9 3.5 20 10.9 36.5 20 36.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z'/%3E%3C/svg%3E")
    center center no-repeat;
  background-size: contain;
  animation: spinleft 0.5s infinite linear;
}

#loadingpage .loading-info {
  position: absolute;
  width: 100%;
  margin-top: 6em;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0a1c2e;
}

.sous-s {
  color: #646878;
  background-color: #eff1f2;
  font-weight: 600;
  align-items: center;
  margin-top: 2px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;
  --bs-badge-padding-x: 0.5rem;
  --bs-badge-padding-y: 0.325rem;
  --bs-badge-font-size: 0.85rem;
  --bs-badge-font-weight: 600;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: 0.425rem;
  padding: 2px 4px;
  font-size: 10px;
}

body {
  position: relative;
}

.tag_cities {
  width: 100%;
  max-width: 100%;
}

.tag_cities .tag {
  background: rgb(49 130 206 / 16%);
  border-radius: 20px;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 28px;
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.7);
  position: relative;
  padding-right: 0;
}

.vs-chip--text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 10px;
}

.vs-chip--close {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  margin: 0 4px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}

.tag_cities .tag [data-role="remove"]::after {
  content: "x";
  padding: 0px 2px;
  margin-bottom: 3px;
  font-size: 15px;
}

.tag_cities .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}

main {
  background-color: #f2f2f2;
}

.v-select .vs__selected {
  white-space: nowrap !important;
}

.v-select .vs__dropdown-toggle {
  overflow-x: hidden !important;
}

.percent .percent__dec,
.percent .dot {
  display: none;
}

.vs__selected-options {
  flex-wrap: inherit;
}

.vue-circular-progress .circle__progress--path {
  stroke-opacity: 0.2 !important;
}

hr.hr-grey {
  margin-bottom: 0;
  margin-top: 5px;
  border-color: #706d6d;
}

hr.hr-grey {
  margin-bottom: 0;
  margin-top: 5px;
  border-color: #706d6d;
}

p.Note {
  font-size: 12px;
  background-color: #f6f6f6;
  padding: 11px;
  display: block;
  margin-bottom: 10px;
}

.table-messages {
  max-height: 500px;
  overflow: auto;
}

.table-messages th {
  font-size: 15px;
}

.error {
  color: #c62525;
}

.success {
  color: #25c631;
}

.warn {
  color: #ff9800;
}

.bg-error {
  background: #fdebeb;
}

.bg-success {
  background: #ccffd0;
}

.bg-warn {
  background: #fff8d1;
}

.tab-cc {
  /* padding: 10px;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 #c4c4c4;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
    border: 0;
    background: #e1e1e1; */
}

.s-sidebarwidget__yellow {
  border-color: hsl(47deg 65% 84%);
  background-color: hsl(47deg 87% 94%);
}

.sidebarwidget__yellow .s-sidebarwidget--header,
.s-sidebarwidget__yellow .s-sidebarwidget--content {
  border-color: hsl(47deg 65% 84%);
}

.s-sidebarwidget--header.s-sidebarwidget__small-bold-text {
  font-size: 14px;
  font-weight: bold;
}

.s-sidebarwidget--header:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.s-sidebarwidget__yellow .s-sidebarwidget--header {
  background-color: #fbf3d5;
  color: #6b737c;
}

.s-sidebarwidget--item {
  margin: 12px 0;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.fc-light {
  color: hsl(210deg 7% 44%) !important;
}

.bbw1 {
  border-bottom-width: 1px !important;
}

.bb {
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
}

.s-sidebarwidget--header {
  padding: 12px 15px;
  background: hsl(0deg 0% 98%);
}

.s-sidebarwidget--header,
.s-sidebarwidget--content {
  border-top: 1px solid hsl(210deg 7% 89%);
}

.s-sidebarwidget {
  position: relative;
  border: 1px solid hsl(210deg 8% 85%);
  border-radius: 3px;
  font-size: 13px;
}

.intro-y {
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.text-smmm {
  font-size: 9px;
}

.box-1 {
  background: #f2f4f7;
  border: 2px solid #e8ebed;
  border-radius: 0.375rem;
}

.loidingconfirm svg {
  width: 30px;
}

.noselect {
  pointer-events: none;
}

.seller-select .vs__dropdown-toggle {
  border: none;
}

.vs__dropdown-toggle {
  border: none;
}

.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}

.disabled-loading {
  pointer-events: none;
  cursor: not-allowed;
}

.vm2_body .ins-progress {
  position: absolute !important;
}

.h-14 {
  height: 3.5rem !important;
}

.modal-footer {
  overflow: auto;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 -4px 1px -4px rgb(0 0 0 / 10%);
  border-top: 1px solid #ededed;
  padding-top: 15px;
  padding-bottom: 15px;
}

.mb-13 {
  margin-bottom: 4rem;
}

.mll-0 {
  margin-left: 0 !important;
}

.vm2__header h4 {
  pointer-events: none;
}

.vm2__header h4,
.vm2__header .vm2__close {
  font-weight: 600;
}

.vm2_wrapper {
  z-index: 11;
}

.vm2_body {
  max-height: 500px;
}

.text-green-600,
.text-green-500 {
  color: #19a69d;
}

.bg-green-600,
.bg-green-700 {
  background-color: #19a69d;
}

.hover\:bg-indigo-darker:hover {
  background-color: #2f365f;
}

.bg-indigo-darker {
  background-color: #2f365f;
}

.bg-indigo {
  background-color: #6574cd;
}

.hover\:bg-indigo:hover {
  background-color: #6574cd;
}

.flag-img {
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: text-top;
  line-height: 16px;
  margin-right: 5px;
}

.form-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  color: currentColor;
  background-color: #fff;
  border-color: #e2e8f0;
  border-width: 1px;
  border-radius: 0.25rem;
  height: 1.2em;
  width: 1.2em;
}

.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.alert-dialog {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 9990;
  padding: 15px 25px;
  color: #444444;
  background-color: white;
  box-shadow: 0 15px 30px 5px #00000045;
  border-radius: 35px;
  cursor: pointer;
}

.alert-dialog.fadein {
  animation: fadein 1s;
}

.alert-dialog.fadeout {
  animation: fadeout 1s;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translate(-50%, 50%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, 50%);
  }
}

.intro-y {
  position: relative;
  transform: translateY(50px);
  -webkit-animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  animation: intro-y-animation 0.4s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.zoom-in:hover {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.043137254901960784);
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.zoom-in {
  cursor: pointer;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
}

.box {
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.043137254901960784);
  background-color: #fff;
  border-radius: 0.375rem;
}

.check {
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
}

i.tiny {
  font-size: 12px;
  line-height: 20px;
}

.feather-icon {
  display: inline-flex;
  align-items: center;
}

.active .check {
  display: block;
}

.intro-y.active {
  border: 2px solid rgba(103, 240, 114, 0.9);
  background: rgba(103, 240, 114, 0.1);
}

/*  Mobile css */
.mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  overflow: visible;
}
</style>

import axios from "../common/axios";

window.alert = function (
  message,
  status = "error",
  timeout = 2,
  position = "center"
) {
  const statuss = {
    success: { color: "white", bg: "#4caf50" },
    error: { color: "white", bg: "#f44336" },
    warning: { color: "white", bg: "#ff9800" },
    default: { color: "white", bg: "#67baff" },
  };
  const positions = {
    center: { top: "50%", left: "50%", bottom: "unset" },
    "top-center": { top: "300px", left: "50%", bottom: "unset" },
    "bottom-center": { bottom: "30px", left: "50%", top: "unset" },
  };
  if (window.dialog) window.dialog.remove();
  window.dialog = document.createElement("div");
  dialog.innerHTML = `
    <div class="alert-dialog fadein" style="
    color:${statuss[status].color};
    background-color:${statuss[status].bg};
    top:${positions["top-center"].top};
    left:${positions["center"].left};
   
    ">${message}</div>`;
  document.querySelector("body").append(window.dialog);
  window.dialog.addEventListener("click", (e) => {
    window.dialog.firstElementChild.classList.add("fadeout");
    setTimeout(() => {
      window.dialog.remove();
    }, 1000);
  });
  setTimeout(() => {
    window.dialog.firstElementChild.classList.add("fadeout");
    setTimeout(() => {
      window.dialog.remove();
    }, 1000);
  }, timeout * 1000);
};
const to = async (promise) => {
  try {
    const response = await promise;
    //
    return response;
  } catch (error) {
    //Check statut
    alert(error.response ? error.response.data : error.message);
    return {};
  }
};

export default {
  //working
  get: async function (module, payload) {
    const { data } = await to(axios.get(`${module}/get`, { params: payload }));
    if (!data) return null;
    return data;
  },
  search: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/search`, { params: payload })
    );

    if (!data) return null;
    return data;
  },
  bigSearch: async function (module, payload) {
    const { data } = await to(axios.post(`${module}/search`, payload));

    if (!data) return null;
    return data;
  },
  //working
  create: async function (module, payload) {
    const { data } = await to(axios.post(`${module}/create`, payload));
    if (!data) return null;
    return data;
  },
  insertMany: async function (module, payload) {
    const { data } = await to(axios.post(`${module}/insertMany`, payload));
    if (!data) return null;
    return data;
  },
  // working
  update: async function (module, payload) {
    const { data } = await to(
      axios.put(`${module}/update?_id=${payload._id}`, payload)
    );
    if (!data) return null;
    return data;
  },
  updateMany: async function (module, payload) {
    const { data } = await to(axios.post(`${module}/updateMany`, payload));
    if (!data) return null;
    return data;
  },
  deleteMany: async function (module, payload) {
    const { data } = await to(axios.post(`${module}/deleteMany`, payload));
    if (!data) return null;
    return data;
  },
  getOrdersByseller: async function (module, payload) {
    const { data } = await to(
      axios.post(`${module}/getOrdersByseller`, payload)
    );
    if (!data) return null;
    return data;
  },
  //working
  delete: async function (module, payload) {
    const { data } = await to(
      axios.delete(`${module}/delete`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  find: async function (module, payload) {
    const { data } = await to(axios.get(`${module}/find`, { params: payload }));
    if (!data) return null;
    return data;
  },
  login: async function (module, payload) {
    const { data } = await to(axios.post(`${module}/login`, payload));
    if (!data) return null;
    return data;
  },
  generateToken: async function (module, payload) {
    const { data } = await to(axios.post(`${module}/generateToken`, payload));
    if (!data) return null;
    return data;
  },
  forgotPassword: async function (module, payload) {
    const { data } = await to(axios.post(`${module}/forgotPassword`, payload));
    if (!data) return null;
    return data;
  },
  verificationCode: async function (module, payload) {
    const { data } = await to(
      axios.post(`${module}/verificationCode`, payload)
    );
    if (!data) return null;
    return data;
  },
  me: async function (module, payload) {
    const { data } = await to(axios.post(`${module}/me`, { params: payload }));
    if (!data) return null;
    return data;
  },
  import: async function (module, payload) {
    const { data } = await to(axios.post(`${module}/import`, payload));
    if (!data) return null;
    return data;
  },
  confirmation: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/confirmation`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  export: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/export`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  convert: async function (module, payload) {
    const { data } = await to(axios.post(`${module}/convert`, payload));
    if (!data) return null;
    return data;
  },

  getOrders: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getOrders`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  getRevenus_and_TopProducts_Stock: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getRevenus_and_TopProducts_Stock`, {
        params: payload,
      })
    );
    if (!data) return null;
    return data;
  },
  getOrdersBetweenTwoDate: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getOrdersBetweenTwoDate`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  getTotalOrders: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getTotalOrders`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  getTotalSeller: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getTotalSeller`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  getTotalProduct: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getTotalProduct`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  getTotalOrdersPerTeleConsultant: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getTotalOrdersPerTeleConsultant`, {
        params: payload,
      })
    );
    if (!data) return null;
    return data;
  },
  getTotalOrdersTeleConsultant: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getTotalOrdersTeleConsultant`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  getOrdersTeleConsultantBetweenTwoDate: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getOrdersTeleConsultantBetweenTwoDate`, {
        params: payload,
      })
    );
    if (!data) return null;
    return data;
  },
  CalculatePercentageStaus: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/CalculatePercentageStaus`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  getTotalOrdersPaid: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getTotalOrdersPaid`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  getTotalStatusPerTeleconsultantBetweenTwoDate: async function (
    module,
    payload
  ) {
    const { data } = await to(
      axios.get(`${module}/getTotalStatusPerTeleconsultantBetweenTwoDate`, {
        params: payload,
      })
    );
    if (!data) return null;
    return data;
  },
  getTotalStatusPerTeleconsultant: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getTotalStatusPerTeleconsultant`, {
        params: payload,
      })
    );
    if (!data) return null;
    return data;
  },
  getTotalDelivery: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getTotalDelivery`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  getConfirmationStatus: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getConfirmationStatus`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  getAllSeller: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getAllSeller`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
  getTotalFeesExpeditions: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/getTotalFeesExpeditions`, { params: payload })
    );
    if (!data) return null;
    return data;
  },

  GenerateCredentials: async function (module, payload) {
    const { data } = await to(
      axios.get(`${module}/GenerateCredentials`, { params: payload })
    );
    if (!data) return null;
    return data;
  },
};

import Vue from "vue";
import Vuex from "vuex";

import wahrhouseModule from "./modules/warhouses.js";
import features from "./modules/features";
import app from "./modules/app";

Vue.use(Vuex);

export default new Vuex.Store({
  state() {
    return {
      open: false,
      loading: false,
      update: false,
      pr_version: 1,
    };
  },
  mutations: {
    actionSidebar(state) {
      state.open = !state.open;
    },
    actionloadingpage: function (state, payload) {
      state.loading = payload.loading;
    },
    actionupdate: function (state, payload) {
      state.update = payload.update;
      if (payload.pr_version) state.pr_version = payload.pr_version;
    },
  },
  actions: {
    actionSidebar(context) {
      context.commit("actionSidebar");
    },
    actionloadingpage(context, payload) {
      context.commit("actionloadingpage", payload);
    },
    actionupdate(context, payload) {
      context.commit("actionupdate", payload);
    },
    transformFeature(context, payload) {
      context.commit("transformFeature", payload);
    },
  },
  getters: {
    ShowSidebar(state) {
      return state.open;
    },
    loadingpage(state) {
      return state.loading;
    },
    checkUpdate(state) {
      return state.update;
    },
    resize() {
      if (window.innerWidth < 960) return true;
      else return false;
    },
  },
  modules: {
    wharhouse: wahrhouseModule,
    features,
    app,
  },
});

import axios from "../common/axios";

export async function transformFeature(data) {
  return await axios.put("/features/transform", data, {
    params: {
      id: data._id,
    },
  });
}

export async function getFeatures() {
  return await axios.get("/features/getFeatures");
}

import { getFeatures, transformFeature } from "../../api/features.api";

// initial state
// shape: [{ id, quantity }]
const state = () => ({
  all: {},
});

// getters
const getters = {};

// actions
const actions = {
  async getAllFeatures({ commit }) {
    await getFeatures().then(({ data }) => {
      commit("setFeatures", data.content);
    });
  },

  async transform({ commit, state }, payload) {
    const toUpdate = state.all[payload.name];
    if (toUpdate) {
      toUpdate.isActive = payload.value;
      await transformFeature(toUpdate);
    }
  },
};

// mutations
const mutations = {
  setFeatures(state, features) {
    state.all = features.reduce((obj, feature) => {
      obj[feature.name] = feature;
      return obj;
    }, {});
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
